import {
  Box,
  // Button,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Message,
  Loader,
  OrderListAdmin,
  OrderListAccount,
} from "../components";

const OrderList = ({ type, shipped }) => {
  const history = useHistory();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }
  }, [history, userInfo]);

  let title = "Team Orders: " + userInfo.bc_customer_displayName;
  if (type === "admin") {
    title = "Dealer-Submitted Orders";
  } else if (type === "myorders") {
    title = "My Orders";
  }

  // // console.log('orderlist shipped', shipped);

  return (
    <>
      <Box display="flex" style={{ alignItems: "center" }}>
        <Typography variant="h6">{title}</Typography>
        <Typography
          variant="caption"
          style={{
            marginLeft: "auto",
            marginRight: 5,
          }}
          color="error"
          align="right"
        >
          *Only Draft or Rejected orders can be deleted.
        </Typography>
      </Box>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message severity="error">{error}</Message>
      ) : type === "admin" ? (
        <OrderListAdmin shipped={shipped} />
      ) : (
        // ) : admin && shipped ? (
        //   <OrderListAdmin shipped={true} />
        <OrderListAccount type={type} />
      )}
    </>
  );
};

export { OrderList };
