import {
  BC_LIST_ITEMS_REQUEST,
  BC_LIST_ITEMS_SUCCESS,
  BC_LIST_ITEMS_FAIL,
  BC_LIST_PORTAL_ITEMS_REQUEST,
  BC_LIST_PORTAL_ITEMS_SUCCESS,
  BC_LIST_PORTAL_ITEMS_FAIL,
  BC_SALES_QUOTE_REQUEST,
  BC_SALES_QUOTE_SUCCESS,
  BC_SALES_QUOTE_FAIL,
  BC_SALES_QUOTE_RESET,
} from '../constants';

export const bcItemsListReducer = (state = {}, action) => {
  switch (action.type) {
    case BC_LIST_ITEMS_REQUEST:
      return { loading: true };
    case BC_LIST_ITEMS_SUCCESS:

      const bcScreenKits = action.payload.bcScreenKits



      const rfKits = action.payload.bcRoofKits
      const frameKits = rfKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('frame')
      );
      const mBeamKits = rfKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('midbeam')
      );
      const aBeamKits = rfKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('accbeam')
      );
      const xBeamKits = rfKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('xtrabeam')
      );
      const bladeKits = rfKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('blade')
      );
      const postKits = rfKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('post')
      );
      const addPartsKits = rfKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('addparts')
      );
      const roofMotorKits = rfKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('motor')
      );
      const bcRoofKits = {
        frameKits,
        mBeamKits,
        aBeamKits,
        xBeamKits,
        bladeKits,
        postKits,
        addPartsKits,
        roofMotorKits,
      };




      const { bcParts } = action.payload;
      const { allBcParts } = action.payload;
      return { loading: false, bcScreenKits, bcRoofKits, bcParts, allBcParts };
    case BC_LIST_ITEMS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const bcPortalItemsListReducer = (state = {}, action) => {
  switch (action.type) {
    case BC_LIST_PORTAL_ITEMS_REQUEST:
      return { loading: true };
    case BC_LIST_PORTAL_ITEMS_SUCCESS:

      let  {
        portalScreenKits,
        portalRoofKits,
        portalParts,
        portalCategories,
      } = action.payload;
    

      return {
        loading: false,
        portalScreenKits,
        portalRoofKits,
        portalParts,
        portalCategories,
      };
    case BC_LIST_PORTAL_ITEMS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const bcSalesQuoteInitReducer = (state = {}, action) => {
  switch (action.type) {
    case BC_SALES_QUOTE_REQUEST:
      return { loading: true };
    case BC_SALES_QUOTE_SUCCESS:
      return { loading: false, salesQuote: action.payload };
    case BC_SALES_QUOTE_FAIL:
      return { loading: false, error: action.payload };
    case BC_SALES_QUOTE_RESET:
      return {};

    default:
      return state;
  }
};