import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { logout } from '../actions';

import { Message, Loader, OrderList, TicketList, WarrantyList } from '../components';

import { makeStyles, Tab, Box, AppBar, Tabs } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AccountView = ({ feedback }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const [value, setValue] = useState(0);

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const bcSalesQuoteInit = useSelector((state) => state.bcSalesQuoteInit);
  const { loading: quoteLoading } = bcSalesQuoteInit;

  function isTokenExpired(token) {
    const expiry = JSON.parse(Buffer.from(token.split('.')[1], 'base64')).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  const tickTab = query.get('tab') === 'tickets';

  useEffect(() => {
    if (!userInfo || isTokenExpired(userInfo?.token)) {
      dispatch(logout());
      history.push('/login');
    }
    if (tickTab) {
      handleChange(null, 2);
    }
  }, [history, tickTab, userInfo, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <h1>Account</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message severity='error'>{error}</Message>
      ) : (
        <div className={classes.root}>
          <AppBar position='static'>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='simple tabs example'
            >
              {userInfo?.storeAccess && (
                <Tab label='My Orders' {...a11yProps(0)} />
              )}
              {userInfo?.storeAccess && (
                <Tab label='Team Orders' {...a11yProps(1)} />
              )}
              <Tab
                label='My Tickets'
                {...a11yProps(userInfo?.storeAccess ? 2 : 0)}
              />
                           
            </Tabs>
          </AppBar>
          {userInfo?.storeAccess && (
            <TabPanel value={value} index={0}>
              {quoteLoading ? <Loader /> : <OrderList type={"myorders"} />}
            </TabPanel>
          )}
            {userInfo?.storeAccess && (
            <TabPanel value={value} index={1}>
              {quoteLoading ? <Loader /> : <OrderList type={"teamorders"} />}
            </TabPanel>
          )}
          <TabPanel value={value} index={userInfo?.storeAccess ? 2 : 0}>
            {loading ? (
              <Loader />
            ) : (
              <TicketList fromAdmin={false} userInfo={userInfo} />
            )}
          </TabPanel>

        </div>
      )}
    </>
  );
};

export { AccountView };
