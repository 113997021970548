import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";

import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {
  withStyles,
  lighten,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

import { listFiles } from "../actions";
import { Loader } from "../components";

const HeaderBox = withStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    padding: `${theme.spacing(2)}px 0`,
    justifyContent: "space-between",
    alignItems: "flex-start",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
}))(Box);

const ToolbarBox = withStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    alignItems: "flex-start",

    [theme.breakpoints.up("md")]: {
      alignItems: "flex-end",
    },
  },
}))(Box);

const ExpandButtonsBox = withStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "flex-start",
    gap: theme.spacing(0.5),

    [theme.breakpoints.up("md")]: {
      justifyContents: "flex-end",
    },
  },
}))(Box);

const SubcategoryHeadingBox = withStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    backgroundColor: (props) =>
      props.expanded
        ? lighten(theme.palette.secondary.light, 0.55)
        : lighten(theme.palette.secondary.light, 0.85),
    borderRadius: 6,
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: lighten(theme.palette.secondary.light, 0.55),
    },
  },
}))(Box);

const SubcategoryTitleBox = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
}))(Box);

const SubcategorySummaryBox = withStyles((theme) => ({
  root: {
    display: "flex",
    flowDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
  },
}))(Box);

const StyledChevronIcons = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    transform: (props) => (props.expanded ? "rotate(180deg)" : "rotate(0deg)"),
    transition: "transform 0.3s",
  },
}))(KeyboardArrowDownIcon);

const SquareBox = withStyles((theme) => ({
  root: {
    width: 32,
    height: 32,
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 6,
    marginRight: theme.spacing(2),
  },
}))(Box);

const StyledVideoIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
  },
}))(VideoLibraryIcon);

const VideoCard = withStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(3),
    height: theme.spacing(22),
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 6,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
  },
}))(Box);

const ExpandAllTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}))(Typography);

const SearchBox = withStyles((theme) => ({
  root: {
    width: `calc(100vw - ${theme.spacing(8)}px)`,

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(40),
    },
  },
}))(Box);

const getVideoName = (video) => {
  let videoName = video.name.split("-").pop().trim();
  videoName = videoName.split(".mp4").shift().trim();
  videoName = videoName[0].toUpperCase() + videoName.slice(1).toLowerCase();

  return videoName;
};

const LibraryVideos = () => {
  const fileList = useSelector((state) => state.fileList);
  const { files, loading, error } = fileList;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const dialogSizeProps = isDesktop
    ? { fullScreen: false, fullWidth: true, maxWidth: "lg" }
    : { fullScreen: true };

  const [videoPlayerDialogIsOpen, setVideoPlayerDialogIsOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    dispatch(listFiles("library", ["Training"]));
  }, [dispatch]);

  const groupedVideos = useMemo(() => {
    const groupVideosByCategory = (videos) => {
      const grouped = {};

      videos.forEach((video) => {
        const parts = video.key.split("/"); // Split key by "/"
        const category = parts[0]; // Main category
        const subcategory = parts[1]; // Subcategory (if any)

        if (!grouped[category]) {
          grouped[category] = {}; // Initialize main category
        }

        if (subcategory) {
          if (!grouped[category][subcategory]) {
            grouped[category][subcategory] = []; // Initialize subcategory
          }

          if (!video.name) {
            return;
          }

          const videoName = getVideoName(video);

          if (
            videoName &&
            (!searchKeyword ||
              videoName.toLowerCase().includes(searchKeyword.toLowerCase()))
          ) {
            grouped[category][subcategory].push({
              ...video,
              name: videoName,
            }); // Add video to subcategory
          }
        }
      });

      return grouped;
    };

    return groupVideosByCategory(files.videos || []);
  }, [files.videos, searchKeyword]);

  const trainingSubcategoriesExpandedState = useMemo(() => {
    if (!groupedVideos?.Training) {
      return {};
    }

    return Object.keys(groupedVideos?.Training).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
  }, [groupedVideos]);

  const autocompleteOptions = useMemo(() => {
    if (!groupedVideos?.Training) {
      return [];
    }

    return Object.entries(groupedVideos?.Training).reduce((acc, curr) => {
      const [subcategory, videos] = curr;
      videos.forEach((video) => {
        acc.push(getVideoName(video));
      });

      return acc;
    }, []);
  }, [groupedVideos]);

  const [expanded, setExpanded] = useState(trainingSubcategoriesExpandedState);
  const [hovered, setHovered] = useState({});

  const handleToggleTrainingSubcategory = (subcategory) => {
    setExpanded((prevState) => ({
      ...prevState,
      [subcategory]: !prevState[subcategory],
    }));
  };

  const handleExpandAllSubcategories = () => {
    const newState = {};
    Object.keys(trainingSubcategoriesExpandedState).forEach((key) => {
      newState[key] = true;
    });

    setExpanded(newState);
  };

  const handleCollapseAllSubcategories = () => {
    const newState = {};
    Object.keys(trainingSubcategoriesExpandedState).forEach((key) => {
      newState[key] = false;
    });

    setExpanded(newState);
  };

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setVideoPlayerDialogIsOpen(true);
  };

  const handleCloseVideoPlayerDialog = () => {
    setSelectedVideo(null);
    setVideoPlayerDialogIsOpen(false);
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <>
      <Box>
        <HeaderBox>
          <Typography variant="h6">Training</Typography>
          <ToolbarBox>
            <ExpandButtonsBox>
              <ExpandAllTypography
                onClick={handleExpandAllSubcategories}
                variant="caption"
              >
                Expand all
              </ExpandAllTypography>
              |
              <ExpandAllTypography
                onClick={handleCollapseAllSubcategories}
                variant="caption"
              >
                Collapse all
              </ExpandAllTypography>
            </ExpandButtonsBox>

            <SearchBox>
              <Autocomplete
                freeSolo
                options={autocompleteOptions}
                onInputChange={(event, value, reason) => {
                  setSearchKeyword(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search videos"
                    margin="normal"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </SearchBox>
          </ToolbarBox>
        </HeaderBox>

        {Object.entries(groupedVideos).map(([category, subcategories]) => {
          if (category.startsWith("Sales")) {
            return null;
          }

          return Object.entries(subcategories).map(([subcategory, videos]) => {
            return (
              <>
                <SubcategoryHeadingBox
                  expanded={expanded[subcategory]}
                  onClick={() => handleToggleTrainingSubcategory(subcategory)}
                  key={subcategory}
                >
                  <SubcategoryTitleBox>
                    <SquareBox>
                      <StyledVideoIcon />
                    </SquareBox>
                    <Typography style={{ fontSize: "1.2rem" }}>
                      {subcategory}
                    </Typography>
                  </SubcategoryTitleBox>
                  <SubcategorySummaryBox>
                    <Typography variant="body2">
                      {videos.length} videos
                    </Typography>
                    <StyledChevronIcons
                      expanded={expanded[subcategory]}
                      fontSize="small"
                    />
                  </SubcategorySummaryBox>
                </SubcategoryHeadingBox>

                <Collapse
                  key={`${category}-${subcategory}`}
                  in={expanded[subcategory]}
                >
                  <Grid container spacing={2}>
                    {videos.map((video) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          key={video.key}
                          onMouseEnter={() =>
                            setHovered((prevState) => ({
                              ...prevState,
                              [video.key]: true,
                            }))
                          }
                          onMouseLeave={() =>
                            setHovered((prevState) => ({
                              ...prevState,
                              [video.key]: false,
                            }))
                          }
                        >
                          <VideoCard onClick={() => handleVideoClick(video)}>
                            <Typography
                              gutterBottom
                              variant="caption"
                              color="secondary"
                            >
                              {subcategory.toLocaleUpperCase()}
                            </Typography>
                            <Typography variant="body1" color="inherit">
                              <strong>{video.name}</strong>
                            </Typography>

                            <Slide
                              direction="up"
                              in={hovered[video.key]}
                              timeout={200}
                              mountOnEnter
                              unmountOnExit
                            >
                              <Fade in={hovered[video.key]}>
                                <PlayCircleOutlineIcon
                                  style={{
                                    position: "absolute",
                                    bottom: 10,
                                    left: 10,
                                  }}
                                />
                              </Fade>
                            </Slide>
                          </VideoCard>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Collapse>
              </>
            );
          });
        })}
      </Box>

      {videoPlayerDialogIsOpen && (
        <Dialog
          open={videoPlayerDialogIsOpen}
          onClose={handleCloseVideoPlayerDialog}
          {...dialogSizeProps}
        >
          <DialogContent
            style={{
              position: "relative",
            }}
          >
            <ReactPlayer
              url={selectedVideo.signedUrl}
              controls
              width="100%"
              height="100%"
              config={{
                file: {
                  attributes: {
                    preload: "auto",
                  },
                },
              }}
              styles={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseVideoPlayerDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export { LibraryVideos };
