import axios from "axios";
import ObjectID from "bson-objectid";

import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_MY_REQUEST,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAIL,
  ORDER_LIST_TEAM_REQUEST,
  ORDER_LIST_TEAM_SUCCESS,
  ORDER_LIST_TEAM_FAIL,
} from "../constants";

/**
 * @typedef {import('../types/orderTypes.js').Order} Order
 */

/**
 * Creates a new order or updates an existing order.
 *
 * @param {Object} existingOrder - The existing order object.
 * @param {Order} newOrder - The new order details.
 * @returns {Function} A function that takes dispatch and getState as arguments and performs the order creation.
 */
export const createOrder =
  (existingOrder, newOrder) => async (dispatch, getState) => {
    const {
      orderName,
      orderNumber,
      status,
      isMaterialTransfer,
      sender,
      receiver,
      extraDetails,
    } = newOrder;

    try {
      dispatch({ type: ORDER_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const completeOrder = {
        ...existingOrder,
        orderId: existingOrder.orderId || ObjectID().toString(),
        orderName: orderName || existingOrder.orderName,
        orderNumber: orderNumber || existingOrder.orderNumber,
        status: status || existingOrder.status,
        transfer: isMaterialTransfer || existingOrder.transfer,
        sender: sender || existingOrder.sender,
        receiver: receiver || existingOrder.receiver,
        extraDetails: extraDetails || existingOrder.extraDetails,
        quoteId: "",
      };

      const res = await axios.post("/api/orders", completeOrder, config);

      dispatch({
        type: ORDER_CREATE_SUCCESS,
        payload: {
          order: res.data,
          status: res.status,
          statusText: res.statusText,
        },
      });
    } catch (err) {
      dispatch({ type: ORDER_CREATE_FAIL, payload: err.message });
    }
  };

export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: "localhost",
        port: 5000,
      },
    };
    const { data } = await axios.get(`/api/orders/${id}`, config);
    // console.log('data: ', data);
    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listMyOrders = (offset) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_MY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: "localhost",
        port: 5000,
      },
    };
    const { data } = await axios.get(
      `/api/orders/myorders?offset=${offset}`,
      config
    );
    console.log("checking data");
    console.log(data);
    dispatch({
      type: ORDER_LIST_MY_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ORDER_LIST_MY_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
export const listTeamOrders = (offset) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_TEAM_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: "localhost",
        port: 5000,
      },
    };
    const { data } = await axios.get(
      `/api/orders/teamorders?offset=${offset}`,
      config
    );
    dispatch({
      type: ORDER_LIST_TEAM_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ORDER_LIST_TEAM_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
export const deleteOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: "localhost",
        port: 5000,
      },
    };
    await axios.delete(`/api/orders/${id}`, config);
    dispatch({
      type: ORDER_DELETE_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: ORDER_DELETE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
