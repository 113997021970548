import React, { useContext, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { StoreContext } from "../context";
import { adminBcCustomerList, createOrder } from "../actions";
import { Loader, Message } from ".";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

/**
 * @typedef {import('../types/orderTypes.js').Order} Order
 */

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    marginRight: "auto",
  },
  dialogBtn: {
    marginLeft: "auto",
    marginRight: 5,
  },
  closeBtn: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
}));

const RowBox = withStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
}))(Box);

const schema = z
  .object({
    orderName: z
      .string()
      .min(3, {
        message: "Order Name is required (at least 3 characters long)",
      })
      .max(50, { message: "Order Name is too long (50 characters max)" }),
    orderNumber: z.string().optional(),
    isMaterialTransfer: z.boolean(),
    sender: z.string().optional(),
    receiver: z.string().optional(),
  })
  .refine(
    (data) => {
      // orderNumber is required when isMaterialTransfer is false
      if (!data.isMaterialTransfer && !data.orderNumber) {
        return false;
      }
      return true;
    },
    {
      message: "Order Number is required",
      path: ["orderNumber"],
    }
  )
  .refine(
    (data) => {
      if (!data.isMaterialTransfer && data.orderNumber) {
        return data.orderNumber.length >= 3;
      }
      return true;
    },
    {
      message: "Order Number needs to be at least 3 digits long",
      path: ["orderNumber"],
    }
  )
  .refine(
    (data) => {
      if (data.isMaterialTransfer) {
        return data.sender.length > 0;
      }
      return true;
    },
    {
      message: "Required for Material Request / Transfer",
      path: ["sender"],
    }
  )
  .refine(
    (data) => {
      if (data.isMaterialTransfer) {
        return data.receiver.length > 0;
      }
      return true;
    },
    {
      message: "Required for Material Request / Transfer",
      path: ["receiver"],
    }
  )
  .refine((data) => data.receiver !== data.sender, {
    message: "Sender and Receiver must be different",
    path: ["receiver"],
  });

function DialogOrderUserContinue({ extraDetails }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ctx = useContext(StoreContext);
  const { cartInfo } = ctx;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    dispatch(adminBcCustomerList());
  }, [dispatch]);

  const adminData = useSelector((state) => state.adminBcCustomerList);
  const { loading, error, bcCustomers } = adminData;

  const corporateSenders = useMemo(() => {
    if (!Array.isArray(bcCustomers)) return [];

    return bcCustomers
      ?.filter(
        (c) =>
          c.bc_customer_displayName.toLowerCase().startsWith("suncoast") &&
          c.bc_customer_displayName !== "Suncoast Australia"
      )
      .map((c) => c.bc_customer_displayName);
  }, [bcCustomers]);

  const corporateReceivers = useMemo(
    () =>
      corporateSenders?.filter(
        (c) => !c.toLowerCase().startsWith("suncoast built")
      ),
    [corporateSenders]
  );

  const methods = useForm({
    defaultValues: {
      orderName: "",
      orderNumber: "",
      isMaterialTransfer: false,
      sender: "",
      receiver: "",
    },
    resolver: zodResolver(schema),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    trigger,
    watch,
  } = methods;
  const { isMaterialTransfer } = watch();

  useEffect(() => {
    if (ctx.isOrder) {
      setIsEditing(true);

      reset({
        orderName: cartInfo.orderName,
        orderNumber: cartInfo.orderNumber,
        isMaterialTransfer: cartInfo.transfer,
      });
    }
  }, [ctx.isOrder, cartInfo, reset]);

  const shouldShowOrderNumber =
    userInfo.isCorporation &&
    (cartInfo.screens.length > 0 ||
      cartInfo.roofs.length > 0 ||
      !isMaterialTransfer);

  const dropDiffs = () => {
    const { screens } = cartInfo;
    const diffs = screens.filter(
      (e) => e.measurements.dropLeftDisplay !== e.measurements.dropRightDisplay
    );
    return diffs;
  };

  const handleCancelSubmit = () => {
    reset();
    setDialogIsOpen(false);
  };

  const submitOrder = (data) => {
    if (data.orderName) {
      const status =
        isEditing && ctx?.isAdmin && cartInfo?.status !== "Draft"
          ? "Approved"
          : "Submitted";

      debugger;

      dispatch(
        createOrder(cartInfo, {
          orderName: data.orderName,
          orderNumber: data.orderNumber,
          status,
          isMaterialTransfer: data.isMaterialTransfer,
          sender: data.sender,
          receiver: data.receiver,
          extraDetails,
        })
      );
    }
  };

  const saveOrder = (data) => {
    if (data.orderName) {
      const status =
        isEditing && ctx.isAdmin && cartInfo.status !== "Draft"
          ? "Submitted"
          : "Draft";

      debugger;

      dispatch(
        createOrder(cartInfo, {
          orderName: data.orderName,
          orderNumber: data.orderNumber,
          status,
          isMaterialTransfer: data.isMaterialTransfer,
          sender: data.sender,
          receiver: data.receiver,
          extraDetails,
        })
      );
    }
  };

  const onSubmitForm = handleSubmit(
    (data, event) => {
      debugger;
      const submitType = event?.nativeEvent?.submitter?.name;

      if (submitType === "saveOrder") {
        saveOrder(data);
      } else if (submitType === "submitOrder") {
        submitOrder(data);
      }

      setDialogIsOpen(false);
    },
    (errors) => {
      console.log("Form errors:", errors);
    }
  );

  const shouldAllowMaterialTransfer =
    userInfo.isCorporation &&
    !cartInfo.screens.length &&
    !cartInfo.roofs.length;

  const dropDifferences = dropDiffs()?.length > 0 ? dropDiffs() : [];
  const allowedStatues = ["Draft", "In Cart"];
  const canView = allowedStatues.includes(cartInfo?.status);
  const canEdit = allowedStatues.includes(cartInfo?.status);

  return (
    <>
      {canView && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setDialogIsOpen(true)}
          className={classes.dialogBtn}
        >
          Continue
        </Button>
      )}

      <Dialog
        open={dialogIsOpen}
        onClose={() => setDialogIsOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Save or Submit Order
          <IconButton
            onClick={() => setDialogIsOpen(false)}
            className={classes.closeBtn}
          >
            <Cancel />
          </IconButton>
        </DialogTitle>

        {loading && <Loader />}

        {!loading && !error && (
          <form onSubmit={onSubmitForm}>
            <DialogContent>
              <Controller
                name="orderName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    autoFocus
                    disabled={!canEdit}
                    error={!!errors.orderName}
                    helperText={errors.orderName?.message}
                    fullWidth
                    label="Order Name"
                    margin="dense"
                  />
                )}
              />

              {shouldAllowMaterialTransfer && (
                <>
                  <FormControl
                    component="fieldset"
                    error={!!errors.isMaterialTransfer}
                  >
                    <Controller
                      name="isMaterialTransfer"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              color="primary"
                              checked={field.value}
                            />
                          }
                          label="Material Request / Transfer"
                        />
                      )}
                    />

                    {errors.isMaterialTransfer && (
                      <FormHelperText error>
                        {errors.isMaterialTransfer.message}
                      </FormHelperText>
                    )}
                  </FormControl>

                  {isMaterialTransfer && (
                    <RowBox>
                      <FormControl fullWidth margin="dense">
                        <InputLabel shrink>Sender</InputLabel>
                        <Controller
                          name="sender"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              onChange={(event) => {
                                field.onChange(event);
                                trigger("mySelect");
                              }}
                            >
                              {corporateSenders.map((location) => (
                                <MenuItem key={location} value={location}>
                                  {location}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.sender && (
                          <FormHelperText error>
                            {errors.sender.message}
                          </FormHelperText>
                        )}
                      </FormControl>

                      <FormControl fullWidth margin="dense">
                        <InputLabel shrink>Receiver</InputLabel>
                        <Controller
                          name="receiver"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              onChange={(event) => {
                                field.onChange(event);
                                trigger("mySelect");
                              }}
                            >
                              {corporateReceivers.map((location) => (
                                <MenuItem key={location} value={location}>
                                  {location}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.receiver && (
                          <FormHelperText error>
                            {errors.receiver.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </RowBox>
                  )}
                </>
              )}

              {shouldShowOrderNumber && (
                <Controller
                  name="orderNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.orderNumber}
                      helperText={errors.orderNumber?.message}
                      fullWidth
                      label="Sales Order / Approved Estimate Number"
                      margin="dense"
                    />
                  )}
                />
              )}

              {dropDifferences?.length > 0 && (
                <div style={{ marginTop: 5 }}>
                  <TableContainer style={{ marginTop: 5 }} component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Screen Name</TableCell>
                          <TableCell>Drop Left</TableCell>
                          <TableCell>Drop Right</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dropDifferences.map((dd) => (
                          <TableRow key={dd.screenId}>
                            <TableCell>{dd.screenName}</TableCell>
                            <TableCell>
                              {dd.measurements.dropLeftDisplay}
                            </TableCell>
                            <TableCell>
                              {dd.measurements.dropRightDisplay}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Message severity="error">
                    Please review the following:
                    <br />
                    These screens will be cut with a{" "}
                    <strong>
                      <u>bottom slope</u>
                    </strong>{" "}
                    to match the difference listed in the measurements below.
                  </Message>
                </div>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleCancelSubmit}
                variant="contained"
                color="secondary"
                size="small"
                className={classes.cancelBtn}
              >
                Cancel
              </Button>

              <Button
                className={classes.saveBtn}
                color="primary"
                name="saveOrder"
                size="small"
                type="submit"
                variant="contained"
              >
                Save Order
              </Button>

              <Button
                color="primary"
                name="submitOrder"
                size="small"
                type="submit"
                variant="contained"
              >
                Submit Order
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
}

export { DialogOrderUserContinue };
