import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StoreContext } from "../context";
import {
  // addToCart,
  createOrder,
  // bcSalesQuote
} from "../actions";
import { Message, RejectDialog } from "../components";

import {
  makeStyles,
  Popover,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  Typography,
  TableCell,
  IconButton,
  Box,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    marginRight: "auto",
  },
  closeBtn: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: theme.palette.secondary.main,
  },
  rejectBtn: {
    marginRight: "auto",
  },
}));

// TODO: refactor this based on the new <DialogOrderUserContinue /> component
function DialogOrderAdminProcess() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ctx = useContext(StoreContext);

  const cart = useSelector((state) => state.cart);
  const { cartInfo } = cart;
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const [isChecked, setIsChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [orderName, setOrderName] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (ctx.isOrder) {
      setEditMode(true);
      setOrderName(cartInfo.orderName);
      setOrderNumber(cartInfo.orderNumber);
      setIsChecked(cartInfo.transfer);
    }
  }, [ctx.isOrder, cartInfo, dispatch, editMode]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOrderNumberChange = (e) => {
    const value = e.target.value;

    // Regular expression to check if value contains non-numeric characters
    const nonNumeric = /[^0-9]/;

    // If the value contains non-numeric characters, open the popover
    if (nonNumeric.test(value)) {
      setAnchorEl(e.currentTarget);
    } else {
      setAnchorEl(null); // Close popover if input is valid
    }

    setOrderNumber(value);
  };

  const handleCloseError = () => {
    setAnchorEl(null);
  };

  const openError = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSave = () => {
    if (orderName?.length > 0) {
      const status =
        editMode && ctx.isAdmin && cartInfo.status !== "Draft"
          ? "Submitted"
          : "Draft";
      dispatch(
        createOrder(cartInfo, {
          orderName,
          orderNumber,
          status,
          isMaterialTransfer: isChecked,
          extraDetails: "",
        })
      );
      setOpen(false);
    }
  };

  const handleSubmit = async () => {
    if (orderName?.length > 0) {
      const status =
        editMode && ctx?.isAdmin && cartInfo?.status !== "Draft"
          ? "Approved"
          : "Submitted";
      if (status === "Approved") {
        dispatch(
          createOrder(cartInfo, {
            orderName,
            orderNumber,
            status,
            isMaterialTransfer: isChecked,
            extraDetails: "",
          })
        );
        // dispatch(bcSalesQuote({ ...cartInfo, orderName: orderName }));
        setOpen(false);
      } else {
        dispatch(
          createOrder(cartInfo, {
            orderName,
            orderNumber,
            status,
            isMaterialTransfer: isChecked,
            extraDetails: "",
          })
        );
        setOpen(false);
      }
    }
  };
  const showSalesNumber = () => {
    if (
      userInfo.isCorporation &&
      (cartInfo.screens.length > 0 || cartInfo.roofs.length > 0 || !isChecked)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    showSalesNumber();
  };
  const dropDiffs = () => {
    const { screens } = cartInfo;
    const diffs = screens.filter(
      (e) => e.measurements.dropLeftDisplay !== e.measurements.dropRightDisplay
    );
    return diffs;
  };

  const dropDifferences = dropDiffs()?.length > 0 ? dropDiffs() : [];

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleClickOpen}
      >
        Process Order
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        style={{ minWidth: "450" }}
      >
        <DialogTitle>
          Process Order
          <IconButton
            onClick={handleClose}
            aria-label="close"
            className={classes.closeBtn}
          >
            <Cancel />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus={!orderName?.length > 0}
            value={orderName}
            required
            margin="dense"
            id="name"
            label="Order Name (Required)"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            onChange={(e) => setOrderName(e.target.value)}
          />
          {userInfo.isCorporation &&
            cartInfo.screens.length === 0 &&
            cartInfo.roofs.length == 0 && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                }
                label="Material Request / Transfer"
              />
            )}
          {showSalesNumber() && (
            <div>
              <Popover
                id={id}
                open={openError}
                anchorEl={anchorEl}
                onClose={handleCloseError}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                disableEnforceFocus
                disableAutoFocus
              >
                <Typography
                  variant="body2"
                  style={{ padding: "8px", color: "red", fontSize: 12 }}
                >
                  Must Be A Valid Number
                </Typography>
              </Popover>
              <TextField
                autoFocus={orderName?.length > 0}
                value={orderNumber}
                required
                margin="dense"
                id="number"
                label="Sales Order / Approved Estimate Number"
                InputLabelProps={{
                  shrink: true,
                }}
                type="text"
                fullWidth
                onChange={handleOrderNumberChange}
              />
            </div>
          )}
          {dropDifferences?.length > 0 && (
            <div style={{ marginTop: 5 }}>
              <TableContainer style={{ marginTop: 5 }} component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Screen Name</TableCell>
                      <TableCell>Drop Left</TableCell>
                      <TableCell>Drop Right</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dropDifferences.map((dd) => (
                      <TableRow key={dd.screenId}>
                        <TableCell>{dd.screenName}</TableCell>
                        <TableCell>{dd.measurements.dropLeftDisplay}</TableCell>
                        <TableCell>
                          {dd.measurements.dropRightDisplay}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Message severity="error">
                Please review the following:
                <br />
                These screens will be cut with a{" "}
                <strong>
                  <u>bottom slope</u>
                </strong>{" "}
                to match the difference listed in the measurements above.
              </Message>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Box className={classes.rejectBtn}>
            <RejectDialog />
          </Box>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            size="small"
            disabled={
              orderName?.length === 0 ||
              (userInfo?.isCorporation &&
                showSalesNumber() &&
                (orderNumber?.length === 0 || isNaN(orderNumber)))
            }
          >
            Save Order
          </Button>
          {cartInfo?.status === "Submitted" && (
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              size="small"
              disabled={
                orderName?.length === 0 ||
                (userInfo?.isCorporation &&
                  showSalesNumber() &&
                  (orderNumber?.length === 0 || isNaN(orderNumber)))
              }
            >
              Approve Order
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export { DialogOrderAdminProcess };
