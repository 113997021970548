import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Toolbar, makeStyles, Button, Drawer, IconButton, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  navLnk: {
    marginRight: theme.spacing(1),
  },
  navBtn: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginRight: "0px"
    },
  },
  toolBar: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: "0px",
      paddingLeft: "0px"
    },
  },
  toolbarButtons: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const Navbar = ({ userInfo }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleItemClick = () => {
    setDrawerOpen(false); 
  };

  const navItems = (
    <>
      {userInfo && userInfo.storeAccess && (
        <ListItem button component={RouterLink} to="/store" onClick={handleItemClick}>
          <ListItemText primary="STORE" />
        </ListItem>
      )}
      <ListItem button component={RouterLink} to="/docs" onClick={handleItemClick}>
        <ListItemText primary="Library" />
      </ListItem>
      <ListItem button component={RouterLink} to="/account" onClick={handleItemClick}>
        <ListItemText primary="Account" />
      </ListItem>
      {userInfo && userInfo.isAdmin && (
        <ListItem button component={RouterLink} to="/admin" onClick={handleItemClick}>
          <ListItemText primary="Admin" />
        </ListItem>
      )}
    </>
  );

  return (
    <Toolbar className={classes.toolBar}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        className={classes.menuButton}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>

      {/* Desktop Buttons */}
      <div className={classes.toolbarButtons}>
        {userInfo && userInfo.storeAccess && (
          <Button
            className={classes.navLnk}
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/store"
            size="small"
          >
            STORE
          </Button>
        )}
        <Button
          className={classes.navLnk}
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/docs"
          size="small"
        >
          Library
        </Button>
        <Button
          className={classes.navLnk}
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/account"
          size="small"
        >
          Account
        </Button>
        {userInfo && userInfo.isAdmin && (
          <Button
            className={classes.navBtn}
            variant="contained"
            color="secondary"
            component={RouterLink}
            to="/admin"
            size="small"
          >
            Admin
          </Button>
        )}
      </div>

      {/* Drawer for Mobile */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>{navItems}</List>
      </Drawer>
    </Toolbar>
  );
};

export { Navbar };
