import React, { useContext, useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import {
  createWarranty,
  updateWarranty
} from '../actions';
import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Typography,
  Box,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { AddBox, Pageview, Edit, Cancel } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  labelText: {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
  },
  sectionHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  commentTextField: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  subItemList: {
    paddingLeft: theme.spacing(4),
  },
  subItemLabel: {
    fontSize: '0.875rem',
  },
  itemCommentTextField: {
    marginTop: theme.spacing(1),
    width: '90%',
  },
}));


function WarrantyDialog({ mode, order, warranty }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [allItems, setItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState('New');


  let Roofitems = [
    "2x8",
    "4x8",
    "Gutter",
    "6x6",
    "Link Bar HD",
    "Pivot Strip",
    "Motor Assembly",
    "Blade",
    "Motor Blade",
    "Fuzz",
    "Gutter Corner",
    "2x6"
  ]
  let Screenitems = [
    "Bottom Bar",
    "Bottom Bar Gasket",
    "Housing",
    "Housing Cover",
    "Track",
    "Track Cover",
    "End Caps",
    "Idler Plate",
    "Motor Crown",
    "Roll Tube",
    "Idler Spring",
    "Drive Tube",
    "Motor",
    "Shuttles",
    "Zipper (Coiled)",
    "Zipper (Molded)",
    "Brush",
    "Angle"
  ]

  useEffect(() => {
    const mergedArray = [...order.roofs, ...order.screens, ...order.parts];
    setItems(mergedArray)
    if (mode === 'view' || mode === 'edit') {
      setComment(warranty.warrantyMessage)
      setCheckedItems(warranty.warrantyItems)
      setStatus(warranty.warrantyStatus)
    }
  }, []);

  const handleCheckboxChange = (event, item) => {
    const itemId = item._id;
    setCheckedItems((prevItems) => {
      if (event.target.checked) {
        return [...prevItems, { itemId, subItems: [], comments: {} }];
      } else {
        return prevItems.filter(checkedItem => checkedItem.itemId !== itemId);
      }
    });
  };


  const handleItemCommentChange = (event, itemId, subItem) => {
    setCheckedItems((prevItems) =>
      prevItems.map((checkedItem) =>
        checkedItem.itemId === itemId
          ? {
              ...checkedItem,
              comments: {
                ...checkedItem.comments,
                [subItem]: event.target.value,
              },
            }
          : checkedItem
      )
    );
  };


  const handleSubItemChange = (event, itemId, subItem) => {
    setCheckedItems((prevItems) =>
      prevItems.map((checkedItem) =>
        checkedItem.itemId === itemId
          ? {
              ...checkedItem,
              subItems: event.target.checked
                ? [...checkedItem.subItems, subItem]
                : checkedItem.subItems.filter((s) => s !== subItem),
            }
          : checkedItem
      )
    );
  };

  const renderSubItems = (itemId, subItems) => {
    return (
      <Grid container direction='column' className={classes.subItemList}>
        {subItems.map((subItem) => {
          const isChecked = checkedItems.find(checkedItem => checkedItem.itemId === itemId)?.subItems.includes(subItem);
          return (
            <Grid item key={subItem}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked || false}
                    onChange={(e) => handleSubItemChange(e, itemId, subItem)}
                    disabled={mode === 'view'}
                  />
                }
                label={<Typography className={classes.subItemLabel}>{subItem}</Typography>}
              />
              {isChecked && (
                <TextField
                  label="Item Comment"
                  variant="outlined"
                  multiline
                  disabled={mode === 'view'}
                  rows={2}
                  className={classes.itemCommentTextField}
                  value={checkedItems.find(checkedItem => checkedItem.itemId === itemId)?.comments[subItem] || ''}
                  onChange={(e) => handleItemCommentChange(e, itemId, subItem)}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };


  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (e, order, warranty) => {
    e.preventDefault();
    if (mode === 'edit') {
      dispatch(updateWarranty(checkedItems, status, comment, order._id, warranty))
    } else {
      console.log(checkedItems)
      console.log(status)
      dispatch(createWarranty(checkedItems, status, comment, order._id))
    }
    handleClose(checkedItems);
  };
  const getItemName = (item) => {
    return item.displayName || item.roofName || item.screenName || 'Unnamed';
  };
  const areAllCommentsFilled = () => {
    return checkedItems.every((checkedItem) => {
      return checkedItem.subItems.every((subItem) => {
        return checkedItem.comments[subItem] && checkedItem.comments[subItem].trim() !== '';
      });
    });
  };
  const isSaveDisabled = checkedItems.length === 0 || comment.trim() === '' || !areAllCommentsFilled();

  return (
    <>
      {mode === 'edit' ? (
        <Link
          onClick={(e) => handleOpen(e)}
          size='small'
          component='button'
          variant='body2'
        >
          <Edit fontSize='small' />
        </Link>
      ) : mode === 'view' ? (
        <Link
          onClick={(e) => handleOpen(e)}
          size='small'
          component='button'
          variant='body2'
        >
          <Pageview fontSize='small' />
        </Link>
      ) : (
        ""
      )
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='ticket-dialog-title'
        style={{ minWidth: '450' }}
      >
        <DialogTitle id='ticket-dialog-title'>
          {mode === 'edit' ? 'Edit' : mode === 'view' ? 'View' : 'New'} Warranty
          <IconButton
            onClick={handleClose}
            aria-label='close'
            className={classes.closeBtn}
          >
            <Cancel />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container direction='column'>
            <Typography variant='body1' className="labelText" gutterBottom>
              Please check the items you would like a warranty on
            </Typography>
            <form onSubmit={(e) => handleSave(e, order)}>
            <Typography variant='h6' className={classes.sectionHeader}>
              Roofs
            </Typography>
            {order.roofs.length > 0 ? (
              order.roofs.map((item) => (
                <Grid item key={item._id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                      disabled={mode === 'view'}
                        checked={checkedItems.some(checkedItem => checkedItem.itemId === item._id)}
                        onChange={(e) => handleCheckboxChange(e, item)}
                      />
                    }
                    label={getItemName(item)}
                  />
                  {checkedItems.some(checkedItem => checkedItem.itemId === item._id) && renderSubItems(item._id, Roofitems)}
                </Grid>
              ))
            ) : (
              <Typography variant='body2' className={classes.labelText}>No roofs</Typography>
            )}

            <Typography variant='h6' className={classes.sectionHeader}>
              Screens
            </Typography>
            {order.screens.length > 0 ? (
              order.screens.map((item) => (
                <Grid item key={item._id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                      disabled={mode === 'view'}
                        checked={checkedItems.some(checkedItem => checkedItem.itemId === item._id)}
                        onChange={(e) => handleCheckboxChange(e, item)}
                      />
                    }
                    label={getItemName(item)}
                  />
                  {checkedItems.some(checkedItem => checkedItem.itemId === item._id) && renderSubItems(item._id, Screenitems)}
                </Grid>
              ))
            ) : (
              <Typography variant='body2' className={classes.labelText}>No screens</Typography>
            )}

            <Typography variant='h6' className={classes.sectionHeader}>
              Parts
            </Typography>
            {order.parts.length > 0 ? (
              order.parts.map((item) => (
                <Grid item key={item._id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                      disabled={mode === 'view'}
                        checked={checkedItems.some(checkedItem => checkedItem.itemId === item._id)}
                        onChange={(e) => handleCheckboxChange(e, item)}
                      />
                    }
                    label={getItemName(item)}
                  />
                </Grid>
              ))
            ) : (
              <Typography variant='body2' className={classes.labelText}>No parts</Typography>
            )}

              <Grid item>
                <FormControl required fullWidth margin='dense'>
                  <InputLabel shrink style={{ fontWeight: 'bold' }}>
                    Warranty Status
                  </InputLabel>
                  {mode === 'edit' ? (
                    <Select
                      required
                      value={status}
                      onChange={(e) =>
                        setStatus(e.target.value)
                      }
                    >
                      <MenuItem value={'New'}>New</MenuItem>
                      <MenuItem value={'In Progress'}>In Progress</MenuItem>
                      <MenuItem value={'Closed'}>Closed</MenuItem>
                    </Select>
                  ) : (
                    <TextField
                      type='text'
                      required
                      disabled
                      value={status}
                      margin='dense'
                    />
                  )}
                </FormControl>
              </Grid>
              <Typography variant='body1' className={classes.labelText} gutterBottom style={{ marginTop: '16px' }}>
                Warranty comment:
              </Typography>
              <TextField
                value={comment}
                onChange={handleCommentChange}
                variant='outlined'
                multiline
                disabled={mode === 'view'}
                minRows={4}
                className="commentTextField"
              />
            </form>
          </Grid>
        </DialogContent>
        <DialogActions>
          {mode !== 'view' && (
            <Button
              onClick={(e) => handleSave(e, order, warranty)}
              variant='contained'
              type='submit'
              color='primary'
              size='small'
              disabled={isSaveDisabled}
            >
              Save Warranty
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export { WarrantyDialog };
